import type { DcChatbot, DcDocument, ReqTypes } from "docuchatcommontypes";
import { DcChatbotStatus, DcEndPoints } from "docuchatcommontypes";
import type { AppFiles, DcDocumentWithFile } from "~~/types/document";

export function useDocument() {
  const t = useNuxtApp().$i18n.t;
  const toast = useToast();

  // State
  const defaultGroupName = "default";

  // Getters
  const user = useUserStore();
  const document = useDocumentStore();
  const chatbot = useChatbotStore();

  // CRUD operations
  async function uploadDocuments(params: { documents: DcDocumentWithFile[]; apps: AppFiles; }, updateCache = false) {
    const documentIds: string[] = [];
    const failMessages: string[] = [];

    if (params.documents.length) {
      const formData = new FormData();
      const documentsWithoutFiles: DcDocument[] = [];

      params.documents.forEach((document) => {
        // We only append the file if it exists
        if (document.file)
          formData.append(document.id, document.file);

        // We remove the file from the document object if it exists
        const { file, ...documentWithoutFile } = document;
        documentsWithoutFiles.push(documentWithoutFile);
      });

      formData.append("meta", JSON.stringify(documentsWithoutFiles));

      // We then upload the formdata
      const endpoint = DcEndPoints.AddDocuments(formData);
      const response = await useApi(endpoint);

      documentIds.push(...(response.content?.documentIds ?? []));
      if (!response.success) {
        failMessages.push(response.message ?? t("common.genericError"));
      }
    }

    if (params.apps.gdrive.files.length) {
      const token = params.apps.gdrive.refreshToken;
      const folderIds = params.apps.gdrive.files.map(f => f.id);

      const endpoint = DcEndPoints.UpsertGoogleDriveIntegration({
        refreshToken: token,
        folderFileIds: folderIds,
      });
      const response = await useApi(endpoint);

      if (response.success) {
        // We just write a placeholder id considering the folders selected already exist in GDrive and we'll be able to get them
        documentIds.push(...params.apps.gdrive.files.map(_ => "gdrive_id"));
      }
      else {
        failMessages.push(response.message ?? t("common.genericError"));
      }
    }

    if (!documentIds.length)
      throw createError(failMessages.join(" "));

    if (updateCache)
      await Promise.all([document.fetchDocuments(), user.fetchAccountUsages()]);

    // If some of the documents were failed to be uploaded, we show a warning
    if (failMessages.length) {
      toast.add({
        title: t("user.chatbots.form.documents.toasts.uploadFailedForSome.title"),
        description: t("user.chatbots.form.documents.toasts.uploadFailedForSome.description", {
          count: documentIds.length,
          total: params.documents.length + params.apps.gdrive.files.length,
          message: failMessages.join(" "),
        }),
        icon: "i-carbon-in-progress-warning",
        color: "amber",
        timeout: 0,
      });
    }
    else {
      toast.add({
        title: t("user.documents.toasts.add.success.title"),
        description: t("user.documents.toasts.add.success.description"),
        icon: "i-carbon-save",
      });
    }

    return {
      documentIds,
    };
  }

  async function downloadDocument(documentId: string) {
    const endpoint = DcEndPoints.DownloadDocument({ documentId });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    base64ToFile(response.content.fullFileName, response.content.contentBase64);
  }

  async function renameDocuments(documents: Array<{
    newName: string;
    documentId: string;
  }>, updateCache = false) {
    const endpoint = DcEndPoints.RenameDocuments({ documents });
    const response = await useApi(endpoint);

    if (!response.success) {
      throw createError({
        message: response.message ?? t("common.genericError"),
      });
    }

    if (updateCache)
      await document.fetchDocuments();
  }

  async function addRemoveFromChatbots(docs: DcDocument[], chatbots: { added: DcChatbot[]; removed: DcChatbot[]; }, updateCache = false) {
    const addPromises: Array<Promise<ReqTypes.EditChatBotRequest>> = chatbots.added.map(async (chatbot) => {
      const formData = chatbotToChatbotForm(chatbot);
      formData.documents.push(...docs);
      const params = await chatbotFormToReqBody(formData);
      return {
        id: chatbot.id,
        ...params,
      };
    });
    const removePromises: Array<Promise<ReqTypes.EditChatBotRequest>> = chatbots.removed.map(async (chatbot) => {
      const formData = chatbotToChatbotForm(chatbot);
      formData.documents = formData.documents.filter(d => !docs.some(doc => doc.id === d.id));
      const params = await chatbotFormToReqBody(formData);
      return {
        id: chatbot.id,
        ...params,
      };
    });

    const chatbotParams = await Promise.all(addPromises.concat(removePromises));

    const successfull: string[] = [];
    const failed: string[] = [];

    const requests = chatbotParams.map((params) => {
      const endpoint = DcEndPoints.EditChatBot(params);
      const req = useApi(endpoint)
        .then(((r) => {
          if (r.success)
            successfull.push(params.name);
          else
            failed.push(params.name);
        }))
        .catch(() => {
          failed.push(params.name);
        });
      return req;
    });

    await Promise.all(requests);

    if (updateCache)
      await Promise.all([chatbot.fetchChatbots(), document.fetchDocuments()]);

    return { successfull, failed };
  }

  async function deleteDocuments(documentIds: string[], updateCache = false) {
    const endpoint = DcEndPoints.DeleteDocuments({ documentIds });
    const response = await useApi(endpoint);

    if (!response.success)
      throw createError(response.message ?? t("common.genericError"));

    if (updateCache)
      // We also fetch chatbots because some chatbot statuses might have changed due to the deletion
      await Promise.all([document.fetchDocuments(), chatbot.fetchChatbots(), user.fetchAccountUsages()]);
  }

  async function fetchDocumentStatuses(documentIds: string[]) {
    const endpoint = DcEndPoints.GetDocumentStatusAndId({ documentIds });
    const response = await useApi(endpoint);

    if (!response.success)
      return [];

    return response.content.idAndStatuses;
  }

  // Helpers
  function sendDocumentStatusNotification(name: string, status: DcChatbotStatus) {
    const toast = useToast();

    switch (status) {
      case DcChatbotStatus.Ready:
        toast.add({
          title: t("user.documents.toasts.ready.title", { name }),
          description: t("user.documents.toasts.ready.description"),
          icon: "i-carbon-document-tasks",
        });

        break;
      case DcChatbotStatus.Failed:
        toast.add({
          title: t("user.documents.toasts.failed.title", { name }),
          description: t("user.documents.toasts.failed.description"),
          icon: "i-carbon-in-progress-error",
          color: "red",
        });

        break;
    }
  }

  return {
    defaultGroupName,

    uploadDocuments,
    downloadDocument,
    renameDocuments,
    addRemoveFromChatbots,
    deleteDocuments,
    fetchDocumentStatuses,

    sendDocumentStatusNotification,
  };
}
